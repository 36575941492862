<template>
    <v-container fluid>
		<v-row justify="center" class="ma-0">
			<v-col class="pa-0">
				<template v-if="affichagePortable">
					<v-select class="mb-2" v-if="acces_autorise(['super Admin'])" :items="listeCentres" label="Centre" prepend-inner-icon="mdi-fire-truck" outlined dense hide-details item-text="nom" return-object v-model="centre_selectionne"></v-select>
					<v-fade-transition mode="out-in">
						<template v-if="annuaire_filtre.length > 0">
							<v-expansion-panels focusable :key="centre_selectionne ? centre_selectionne.id : 'null'">
								<v-expansion-panel v-for="membre in annuaire_filtre" :key="membre.id" class="my-1 panel_annuaire elevation-0">
										<v-expansion-panel-header class="header_annuaire" hide-actions>
											<v-list-item two-line>
												<v-list-item-avatar color="blue-grey lighten-3">
													<img v-if="membre.user.photo" :src="getPhoto(membre.user.photo)">
													<span v-else class="white--text text-h6 font-weight-light">{{premiereLettreMaj(membre.user.prenom)+premiereLettreMaj(membre.user.nom)}}</span>
												</v-list-item-avatar>

												<v-list-item-content>
													<v-list-item-title>{{ membre.user.nom + ' ' +  membre.user.prenom}}</v-list-item-title>
													<v-list-item-subtitle>{{ membre.equipe ? membre.equipe.nom + ' '+ (membre.equipe.est_spp ? 'SPP' : 'SPV') : '' }}</v-list-item-subtitle>
												</v-list-item-content>
											</v-list-item>
										</v-expansion-panel-header>
										<v-expansion-panel-content class="text-center pt-4">
											<div v-if="membre.user.telephone && !membre.user.coordonnees_privees" class="mb-3"><v-icon class="mr-2">mdi-phone-outline</v-icon>{{membre.user.telephone}}</div>
											<div v-if="membre.user.email && !membre.user.coordonnees_privees" class="mb-3"><v-icon class="mr-2">mdi-email-outline</v-icon>{{membre.user.email}}</div>
											<v-btn class="mx-2" outlined fab color="primary" :disabled="membre.user.telephone == null || membre.user.coordonnees_privees" :href="'tel:' + membre.user.telephone">
												<v-icon>mdi-phone-outline</v-icon>
											</v-btn>
											<v-btn class="mx-2" outlined fab color="primary" :disabled="membre.user.email == null || membre.user.coordonnees_privees" :href="'mailto:' + membre.user.email">
												<v-icon>mdi-email-plus-outline</v-icon>
											</v-btn>
											<v-btn class="mx-2" outlined fab color="primary" :disabled="membre.user.telephone == null || membre.user.coordonnees_privees" :href="'sms:' + membre.user.telephone">
												<v-icon>mdi-comment-text-outline</v-icon>
											</v-btn>
										</v-expansion-panel-content>
								</v-expansion-panel>
							</v-expansion-panels>
						</template>
						<v-row v-else class="ma-0 mt-12" justify="center" :key="null">
							<div class="font-italic">Aucun membre trouvé</div>
						</v-row>
					</v-fade-transition>
				
				</template>
				<template v-else>
					<v-row class="ma-0 mb-2">
						<v-select class="mb-4" v-if="acces_autorise(['super Admin'])" style="max-width: 300px" :items="listeCentres" label="Centre" prepend-inner-icon="mdi-fire-truck" outlined dense hide-details item-text="nom" return-object v-model="centre_selectionne"></v-select>
						<v-spacer/>
						<v-text-field label="Recherche" dense outlined clearable hide-details prepend-inner-icon="mdi-magnify" v-model="texte_recherche" style="max-width: 300px"></v-text-field>
					</v-row>
					<v-row class="ma-0 liste_annuaire" justify='center'>
						<v-fade-transition mode="out-in">
							<v-row class="ma-0" justify='center' v-if="annuaire_filtre.length > 0" :key="centre_selectionne ? centre_selectionne.id : 'null'">
							
								<v-card v-for="membre in annuaire_filtre" :key="membre.id" width="200" flat outlined class="mx-1 mb-2 pb-2">
									<v-row class="ma-0">
										<v-col class="pa-0" align="center">
											<v-avatar color="blue-grey lighten-3" size="80" class="my-6">
												<img v-if="membre.user.photo" :src="getPhoto(membre.user.photo)">
												<span v-else class="white--text text-h4 font-weight-light">{{premiereLettreMaj(membre.user.prenom)+premiereLettreMaj(membre.user.nom)}}</span>
											</v-avatar>
											<div>{{ membre.user.nom + ' ' +  membre.user.prenom}}</div>
											<div class="mb-2 text-caption deep-purple--text">{{ membre.equipe ? membre.equipe.nom + ' '+ (membre.equipe.est_spp ? 'SPP' : 'SPV') : '' }}</div>
											<div>
												<v-icon class="mr-2" size="20">mdi-phone-outline</v-icon>
												<span v-if="membre.user.telephone && !membre.user.coordonnees_privees" class="text-caption">{{membre.user.telephone}}</span>
												<span v-else class="mx-12">-</span>
											</div>
											<div @click="envoyerMail(membre)" :style="membre.user.email && !membre.user.coordonnees_privees ? 'cursor: pointer' : ''">
												<v-icon class="mr-2" size="20">mdi-email-outline</v-icon>
												<span v-if="membre.user.email && !membre.user.coordonnees_privees" class="text-caption">{{membre.user.email}}</span>
												<span v-else class="mx-12">-</span>
											</div>
											
										</v-col>
									</v-row>
								</v-card>
							
							</v-row>
							<div class="font-italic mt-12" v-else>Aucun membre trouvé</div>
						</v-fade-transition>
					</v-row>
				</template>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
    import axios from 'axios'
    export default {
        created() {
			this.$store.commit('majTitrePage', 'Annuaire')
        },
        mounted() {
			this.$store.dispatch('getListeCentres').then( () => {
				//	initialisation du centre
				this.centre_selectionne = this.$store.getters.membreCourant.centre
                this.$store.dispatch('getAnnuaire', this.centre_selectionne.id)
			})
			
			
			// this.centre_selectionne = this.$store.getters.membreCourant.centre.id
			// this.$store.dispatch('getAnnuaire', this.centre_selectionne)
        },
        data() {
			return {
				centre_selectionne: null,
				chargement_annuaire: false,
				mode_filtrage: false,	//	false: global, true: par équipe  
				texte_recherche: '',              
				show: false
			}
		},
		computed: {
			affichagePortable() {
				return this.$vuetify.breakpoint.name == 'xs'
			},
			annuaire() {
				return this.$store.getters.annuaire
			},
			annuaire_filtre() {
				const liste_complete = this.$store.getters.annuaire
				if(this.texte_recherche){
                    return liste_complete.filter( element => {
                        return (
								element.user.nom.toLowerCase().indexOf(this.texte_recherche.toLowerCase()) > -1
                            || element.user.prenom.toLowerCase().indexOf(this.texte_recherche.toLowerCase()) > -1
                        )
                    })
                }
                return liste_complete
			},
			centre_courant() {
				return this.$store.getters.membreCourant.centre
			},
			listeCentres() {
				return this.$store.getters.liste_centres
			},
  
		},
		methods: {
			acces_autorise(permissions_requises) {
                // retourne true si l'utilisateur à les permissions listées
                
                let permission_trouvee = false

                if(permissions_requises.length > 0 && this.$store.getters.membreCourant) {
                
                    const roles_utilisateur = this.$store.getters.membreCourant.roles
                    const permissions_utilisateur = this.$store.getters.membreCourant.permissions

                    const estSuperAdmin = roles_utilisateur.find(role => role.name == 'Super admin')
                    

                    //	si super admin on autorise tout
                    if (estSuperAdmin) {
                        permission_trouvee = true
                    }
                    else {
                        //	on cherche si la permission demandée se trouve dans un des roles de l'utilisateur
                        roles_utilisateur.forEach(role => {
                            role.permissions.forEach(permission => {
                                    if( permissions_requises.includes(permission.name) ) {
                                        permission_trouvee = true
                                    }
                                })
                        })
                    

                        // si la permission n'a pas déjà été trouvée, on cherche si elle est dans les permissions directes
                        if (!permission_trouvee) {
                            
                            permissions_utilisateur.forEach(permission => {
                                if( permissions_requises.includes(permission.name) ) {
                                    permission_trouvee = true
                                }
                            })
                        }
                    }
                }

                //	on autorise si pas de permission ou si une permission et que la permission est trouvée
                return permissions_requises.length == 0 || (permissions_requises.length > 0 && permission_trouvee)
			},
			envoyerMail(membre) {
				if(membre.user.email && !membre.user.coordonnees_privees) {
					window.open('mailto:'+membre.user.email)
				}
			},
			getPhoto(chemin){
				return "/storage/"+chemin;
			},
			onChangeModeFiltrage() {
				console.log('Mode filtrage: ',this.mode_filtrage)
			},
			premiereLettreMaj(text){
				return text.charAt(0).toUpperCase();
			},
			snackbar(type,message){
                this.$store.commit('afficheSnackbar', {type: type, message: message});
			}

        },
        validations: {
            
		},
		watch: {
			centre_courant() {
                this.centre_selectionne = this.$store.getters.membreCourant.centre
            },
			centre_selectionne() {
				this.$store.dispatch('getAnnuaire', this.centre_selectionne.id)
				//	on remet un filtre par ordre alpha si pas d'équipes
				if(!this.equipes_existantes) this.mode_filtrage = false

			}
		}
  	}
</script>

<style>

.liste_annuaire {
	overflow-y: auto;
	max-height: calc(100vh - 180px);
	scrollbar-width: thin;
	/* min-height: calc(100vh - 115px); */
}

.header_annuaire {
	padding-left: 0px !important;
	padding-top: 0px !important;
	padding-bottom: 0px !important;
	
}

.panel_annuaire  {
	border-style: solid;
	border-width: 1px;
	border-color: rgb(224, 224, 224);
	
}

.v-expansion-panel:before {
  box-shadow: none !important;
  
}

.v-expansion-panel {
	border-top: 0;
}


</style>